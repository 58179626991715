// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bathroom-tsx": () => import("./../../../src/pages/bathroom.tsx" /* webpackChunkName: "component---src-pages-bathroom-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-sanity-post-slug-current-tsx": () => import("./../../../src/pages/blog/{sanityPost.slug__current}.tsx" /* webpackChunkName: "component---src-pages-blog-sanity-post-slug-current-tsx" */),
  "component---src-pages-home-upgrades-tsx": () => import("./../../../src/pages/home-upgrades.tsx" /* webpackChunkName: "component---src-pages-home-upgrades-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-outdoor-decks-tsx": () => import("./../../../src/pages/outdoor-decks.tsx" /* webpackChunkName: "component---src-pages-outdoor-decks-tsx" */),
  "component---src-pages-sanity-page-slug-current-tsx": () => import("./../../../src/pages/{sanityPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-tsx" */)
}

